import React, { memo, useDeferredValue } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { CLOSE_RESULTS_LABEL } from 'site-modules/shared/constants/global-search/global-search';
import { GlobalSearchResults } from 'site-modules/shared/components/inventory/global-search/global-search-results/global-search-results';

import './global-search-dropdown.scss';

function GlobalSearchDropdownUI({
  isOpen,
  searchQuery,
  onCloseButtonClick,
  isError,
  fastMatcherData,
  isFastMatcherLoading,
  isSelectOptionMessageShown,
  searchId,
  creativeId,
  withLlmSearchDisabled,
}) {
  const deferredQuery = useDeferredValue(searchQuery);

  return (
    <div className={classnames('global-search-dropdown px-1 pb-1 pos-r', { show: isOpen })}>
      <GlobalSearchResults
        isMobile={false}
        searchQuery={deferredQuery}
        isOpen={isOpen}
        fastMatcherData={fastMatcherData}
        isError={isError}
        isFastMatcherLoading={isFastMatcherLoading}
        isSelectOptionMessageShown={isSelectOptionMessageShown}
        searchId={searchId}
        withLlmSearchDisabled={withLlmSearchDisabled}
        creativeId={creativeId}
      />
      <div className="close-search-dropdown pos-a">
        <button
          type="button"
          onClick={onCloseButtonClick}
          aria-label={CLOSE_RESULTS_LABEL}
          title={CLOSE_RESULTS_LABEL}
          className="btn close visually-hidden visually-hidden-focusable"
        >
          <span className="icon-cross3" aria-hidden />
        </button>
      </div>
    </div>
  );
}

GlobalSearchDropdownUI.propTypes = {
  isOpen: PropTypes.bool,
  searchQuery: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  isError: PropTypes.bool,
  isFastMatcherLoading: PropTypes.bool,
  isSelectOptionMessageShown: PropTypes.bool,
  searchId: PropTypes.string,
  creativeId: PropTypes.string,
  withLlmSearchDisabled: PropTypes.bool,
};

GlobalSearchDropdownUI.defaultProps = {
  isOpen: false,
  searchQuery: '',
  onCloseButtonClick: noop,
  fastMatcherData: null,
  isError: false,
  isFastMatcherLoading: false,
  isSelectOptionMessageShown: false,
  searchId: '',
  creativeId: '',
  withLlmSearchDisabled: false,
};

export const GlobalSearchDropdown = memo(GlobalSearchDropdownUI);
