import PropTypes from 'prop-types';
import { gql } from '@apollo/client'; // eslint-disable-line
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { transformMakeModel } from 'client/data/transforms/editorial-reviews/transform-make-model';
import {
  transformMakeModelYear,
  transformPreProdContent,
} from 'client/data/transforms/editorial-reviews/transform-make-model-year';
import { EdmundsAPI } from 'client/data/api/api-client';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { fetchContent } from 'client/data/cms/fetch-content';
import { buildNoSubmodelVehiclePath, buildVehiclePath, VehicleModel } from './vehicle';

const ImageLink = PropTypes.shape({
  author: PropTypes.string,
  captionTranscript: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  title: PropTypes.string,
});

const Rating = PropTypes.shape({
  text: PropTypes.string,
  title: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  embeds: PropTypes.arrayOf(PropTypes.shape()),
});

export const SafetyFeaturesEntity = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  })
);

export const EditorialRatingsEntities = PropTypes.shape({
  acceleration: Rating,
  audioNav: Rating,
  brakes: Rating,
  cargoSpace: Rating,
  childSafetySeat: Rating,
  climateControl: Rating,
  comfort: Rating,
  drivability: Rating,
  driverAids: Rating,
  driving: Rating, // shared field
  drivingPosition: Rating,
  easeUse: Rating,
  gettingInOut: Rating,
  handling: Rating,
  hauling: Rating,
  interior: Rating, // shared field
  mobileWeb: Rating,
  noiseVibration: Rating,
  offRoad: Rating,
  overall: Rating,
  quality: Rating,
  rideComfort: Rating,
  roominess: Rating,
  seatComfort: Rating,
  smallItemStorage: Rating,
  smartphone: Rating,
  steering: Rating,
  technology: Rating,
  towing: Rating,
  utility: Rating,
  visibility: Rating,
  voiceControl: Rating,
  mpg: Rating,
  value: Rating,
  wildcard: Rating,
});

export const FirstContentArticleEntity = PropTypes.oneOfType([
  PropTypes.shape({
    publishDate: PropTypes.string,
    dateModified: PropTypes.string,
    metaDescription: PropTypes.string,
    titleTag: PropTypes.string,
    author: PropTypes.shape({
      authorLink: PropTypes.string,
      authorName: PropTypes.string,
      authorTitle: PropTypes.string,
      photoPath: PropTypes.string,
    }),
    content: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          content: PropTypes.string,
          subTitle: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
        }),
        PropTypes.shape({
          type: PropTypes.string,
          links: PropTypes.arrayOf(
            PropTypes.shape({
              href: PropTypes.string,
              captionTranscript: PropTypes.string,
            })
          ),
        }),
        PropTypes.shape({
          type: PropTypes.string,
          videoIds: PropTypes.shape({
            'youtube-videoid': PropTypes.string,
          }),
        }),
      ])
    ),
  }),
  PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
        captionTranscript: PropTypes.string,
      })
    ),
  }),
]);

export const FirstContentEntity = PropTypes.shape({
  articles: PropTypes.arrayOf(FirstContentArticleEntity),
  media: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      rel: PropTypes.string,
    })
  ),
  additionalLinks: PropTypes.shape({
    title: PropTypes.string,
    moreLink: PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        href: PropTypes.string,
        date: PropTypes.string,
      })
    ),
  }),
  releaseDate: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  price: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  bullets: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
  reviewSubmodels: PropTypes.objectOf(PropTypes.string),
  hasPreProdContent: PropTypes.bool,
  isSubmodelSpecific: PropTypes.bool,
});

const AuthorData = PropTypes.shape({
  authorTitle: PropTypes.string,
  authorLink: PropTypes.string,
  aboutAuthor: PropTypes.string,
  authorName: PropTypes.string,
  photoPath: PropTypes.string,
});

export const EditorialReviewEntities = {
  AuthorData,
  Author: PropTypes.shape({
    contentMetadata: AuthorData,
  }),
  OverallRating: PropTypes.shape({ rating: PropTypes.number }),
  MakeModel: PropTypes.shape({ introduction: PropTypes.string, metaDescription: PropTypes.string }),
  EditorialReview: PropTypes.shape({
    // shared fields
    galleryImages: PropTypes.arrayOf(ImageLink),
    heroImage: ImageLink,
    id: PropTypes.string,
    interiorImages: PropTypes.arrayOf(ImageLink),
    whatsNew: PropTypes.string,
    title: PropTypes.string,

    // legacy fields
    body: PropTypes.string,
    driving: PropTypes.string,
    edmundsSays: PropTypes.string,
    powertrain: PropTypes.string,
    review: PropTypes.string,
    introduction: PropTypes.string,
    safety: PropTypes.string,

    // newer fields
    calloutQuote: PropTypes.string,
    summary: PropTypes.string,
    summarySnippet: PropTypes.string,
    ratingDisclaimer: PropTypes.string,
    trimFeatures: PropTypes.string,
    trimFeaturesTitle: PropTypes.string,
    trimFeaturesSnippet: PropTypes.string,
    trimTested: PropTypes.string,
    weRecommend: PropTypes.string,
    weRecommendTitle: PropTypes.string,
    goodCar: PropTypes.string,
    authorPath: PropTypes.string,

    // ratings
    ratings: EditorialRatingsEntities,

    // pros,cons
    pros: PropTypes.arrayOf(PropTypes.string),
    cons: PropTypes.arrayOf(PropTypes.string),

    // safety features
    safetyFeatures: SafetyFeaturesEntity,

    firstContent: FirstContentEntity,
  }),

  ArticlesList: PropTypes.shape({
    totalNumber: PropTypes.number,
    totalPages: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        make: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        model: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        year: PropTypes.number,
        title: PropTypes.string,
        contentPath: PropTypes.string,
        thumbnailAssetId: PropTypes.string,
        published: PropTypes.number,
      })
    ),
  }),
  VanillaComments: PropTypes.arrayOf(
    PropTypes.shape({
      Body: PropTypes.string,
      CommentID: PropTypes.number,
      DateUpdated: PropTypes.string,
      DateInserted: PropTypes.string,
      InsertName: PropTypes.string,
      InsertPhoto: PropTypes.string,
    })
  ),
};

/**
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildReviewPath({ make, model, submodel, year }) {
  return submodel
    ? `${buildVehiclePath({ make, model, year, submodel })}.editorialReview.review`
    : `${buildNoSubmodelVehiclePath({ make, model, year })}.editorialReview.review`;
}

export function buildPreProdContentPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].preProdContent`
    : `makes["${make}"].models["${model}"].years["${year}"].preProdContent`;
}

export function buildPreProdContentAuthorPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].preProdContent.author`
    : `makes["${make}"].models["${model}"].years["${year}"].preProdContent.author`;
}

export function buildRecommendedTrimPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].recommendedTrim`;
}

export function buildEditorialArticlesPath({
  make = 'all',
  model = 'all',
  types = 'roadTest,review',
  pageNum = 1,
  pageSize = 10,
  sortBy = 'date:desc',
}) {
  return `makes["${make}"].models["${model}"].articles.types["${types}"].pageNum["${pageNum}"].pageSize["${pageSize}"].sortBy["${sortBy}"]`;
}

export function buildAuthorPath({ make, model, submodel, year }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].editorialReview.author`
    : `makes["${make}"].models["${model}"].years["${year}"].editorialReview.author`;
}

export function buildRatedByPath({ make, model, submodel, year }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].editorialReview.ratedBy`
    : `makes["${make}"].models["${model}"].years["${year}"].editorialReview.ratedBy`;
}

export const EditorialReviewModel = createModelSegment('editorialReview', [
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].review',
    resolve(match, context) {
      const { make, model, year } = match;
      const path = `${make}/${model}/${year}/review`;
      return fetchContent(path, context);
    },
  },
  /**
   * @see buildReviewPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].editorialReview.review',
    resolve(match, context) {
      const { make, model, year } = match;
      const NEW_REVIEWS_YEAR_LOWERBOUND = 2017;
      if (year < NEW_REVIEWS_YEAR_LOWERBOUND) {
        const path = `/${make}/${model}/${year}/index`;
        return fetchContent(path, context)
          .then(apiResponse => transformMakeModelYear(apiResponse))
          .catch(() => null); // Return null for vehicles without reviews
      }
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].review`)
        .then(apiResponse => transformMakeModelYear(apiResponse))
        .catch(() => null); // Return null for vehicles without reviews
    },
  },
  /**
   * @see buildReviewPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].editorialReview.review',
    resolve(match, context) {
      const { make, model, submodel, year } = match;

      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].editorialReview.review`)
        .then(review => {
          const submodelReview =
            review && review.reviewSubmodels ? JSON.parse(review.reviewSubmodels.replace(/'/g, '"')) : {};
          const submodelAtom = submodelReview[submodel];
          const path = `${make}/${model}/${year}/${submodelAtom}`;

          if (submodelAtom) {
            return fetchContent(path, context)
              .then(apiResponse => transformMakeModelYear(apiResponse))
              .catch(() => null);
          }
          return review || null;
        });
    },
  },
  {
    path: 'styles["{styleId}"].editorialReview.review',
    async resolve({ styleId }, context) {
      return context
        .resolveValue(`styles.${styleId}`, VehicleModel)
        .then(
          style =>
            style &&
            context.resolveValue(
              `makes["${style.makeSlug}"].models["${style.modelSlug}"].submodels["${style.submodels.slug}"].years["${
                style.year
              }"].editorialReview.review`
            )
        );
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].editorialReview',
    resolve(match, context) {
      const { make, model } = match;

      return fetchContent(`${make}/${model}/index`, context).then(apiResponse => transformMakeModel(apiResponse));
    },
  },
  /**
   * @see buildEditorialArticlesPath
   * Example: https://www.edmunds.com/api/editorial/v3/articles/?make=honda&model=civic&types=roadTest,review&sortBy=date:DESC&pagesize=10&pagenum=1
   * @param types roadTest, comparisonTest, review
   * @param sortBy date:asc date:desc title:asc title:desc
   * @return EditorialReviewEntities.ArticlesList
   */
  {
    path:
      'makes["{make}"].models["{model}"].articles.types["{types}"].pageNum["{pageNum}"].pageSize["{pageSize}"].sortBy["{sortBy}"]',
    resolve({ make, model, types, pageNum, pageSize, sortBy }, context) {
      const url = `/editorial/v3/articles/?${make === 'all' ? '' : `make=${make}&`}${
        model === 'all' ? '' : `model=${model}&`
      }types=${types}&sortBy=${sortBy}&pagesize=${pageSize}&pagenum=${pageNum}`;

      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  /**
   * @see buildAuthorPath()
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].editorialReview.author',
    resolve(match, context) {
      const { make, model, year } = match;
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].editorialReview.review`)
        .then(editorialReview => {
          const authorPath = editorialReview && editorialReview.authorPath;
          if (authorPath) {
            return fetchContent(authorPath, context);
          }
          return null;
        });
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].editorialReview.author',
    resolve({ make, model, submodel, year }, context) {
      return context
        .resolveValue(
          `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].editorialReview.review`
        )
        .then(editorialReview => {
          const authorPath = get(editorialReview, 'authorPath');
          return authorPath
            ? fetchContent(authorPath, context).catch(() => null)
            : context.resolveValue(buildAuthorPath({ make, model, year })).then(() => ({
                $ref: `#/makes/${make}/models/${model}/years/${year}/editorialReview/author`,
              }));
        });
    },
  },
  /**
   * @see buildRatedByPath()
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].editorialReview.ratedBy',
    resolve(match, context) {
      const { make, model, year } = match;
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].editorialReview.review`)
        .then(editorialReview => {
          const ratedByPath = editorialReview && editorialReview.ratedByPath;
          if (ratedByPath) {
            return fetchContent(ratedByPath, context);
          }
          return null;
        });
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].editorialReview.ratedBy',
    resolve({ make, model, submodel, year }, context) {
      return context
        .resolveValue(
          `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].editorialReview.review`
        )
        .then(editorialReview => {
          const ratedByPath = get(editorialReview, 'ratedByPath');
          return ratedByPath
            ? fetchContent(ratedByPath, context).catch(() => null)
            : context.resolveValue(buildAuthorPath({ make, model, year })).then(() => ({
                $ref: `#/makes/${make}/models/${model}/years/${year}/editorialReview/ratedBy`,
              }));
        });
    },
  },
  /**
   * @see buildPreProdContentPath()
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].preProdContent',
    resolve({ make, model, year }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].review`)
        .then(transformPreProdContent)
        .catch(() => null); // Return null for vehicles without pre-prod content
    },
  },
  /**
   * @see buildPreProdContentPath()
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].preProdContent',
    resolve({ make, model, submodel, year }, context) {
      return context.resolveValue(buildPreProdContentPath({ make, model, year })).then(async preProdContent => {
        const submodelAtom = get(preProdContent, `reviewSubmodels["${submodel}"]`);

        if (!submodelAtom) {
          return { $ref: `#/makes/${make}/models/${model}/years/${year}/preProdContent` };
        }

        return fetchContent(`${make}/${model}/${year}/${submodelAtom}`, context)
          .then(apiResponse => transformPreProdContent(apiResponse, true))
          .catch(() => null);
      });
    },
  },
  /**
   * @see buildPreProdContentAuthorPath()
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].preProdContent.author',
    resolve({ make, model, submodel, year }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].preProdContent`)
        .then(preProdContent => {
          const authorPath = get(preProdContent, 'authorPath');
          return authorPath
            ? fetchContent(authorPath, context).catch(() => null)
            : context.resolveValue(buildPreProdContentAuthorPath({ make, model, year })).then(() => ({
                $ref: `#/makes/${make}/models/${model}/years/${year}/preProdContent/author`,
              }));
        });
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].preProdContent.author',
    resolve(match, context) {
      const { make, model, year } = match;
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].preProdContent`)
        .then(preProdContent => {
          const authorPath = preProdContent && preProdContent.authorPath;
          return authorPath ? fetchContent(authorPath, context).catch(() => null) : null;
        });
    },
  },
  /**
   * @see buildRecommendedTrimPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].recommendedTrim',
    resolve({ make, model, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($make: String!, $model: String!, $year: String!) {
              editorialTrims(make: $make, model: $model, year: $year) {
                trim
                recommended
              }
            }
          `,
          { make, model, year }
        )
        .then(response =>
          get(response?.editorialTrims?.find(({ recommended }) => recommended === 'true'), 'trim', null)
        );
    },
  },
]);
