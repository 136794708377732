import { flatten, get, isEmpty } from 'lodash';
import {
  buildCombinedRangeLabel,
  buildFacetRangeFilterValue,
} from 'site-modules/shared/utils/inventory-utils/range-facet-utils';
import { facetsConfig } from 'site-modules/shared/constants/inventory/facets-config';

function getRegularFacet({ type, values = [] }) {
  return values.map(({ value, name }) => ({ type, value, name: name || value, selected: true }));
}

function getRangeFacet(facet) {
  const type = get(facet, 'type', '');
  const min = get(facet, 'min');
  const max = get(facet, 'max');

  if (!min && !max) {
    return null;
  }

  return {
    type,
    value: buildFacetRangeFilterValue(min, max),
    name: buildCombinedRangeLabel(min, max, facetsConfig[type]),
    selected: true,
  };
}

export function transformSuggestedFilters(suggestedFilters) {
  if (isEmpty(suggestedFilters)) return suggestedFilters; // response is not received yet

  const {
    facetsByName = [],
    inventoryCount,
    modifiedRadius,
    modifiedZip,
    identifiedVin,
    deltaRadius,
  } = suggestedFilters;

  const filters = flatten(
    facetsByName.map(facet => {
      const rangeFacet = get(facet, 'rangeFacet', false);
      return rangeFacet ? getRangeFacet(facet) : getRegularFacet(facet);
    })
  );

  return {
    filters: filters.filter(facet => facet),
    inventoryCount,
    modifiedRadius,
    modifiedZip,
    identifiedVin,
    deltaRadius,
  };
}
