import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import { noop } from 'lodash';
import { FocusScope, FocusRing } from '@react-aria/focus';
/* Utils */
import { getStaticImageUrl } from 'client/utils/image-helpers';
import {
  fireAbandonTracking,
  fireResetTracking,
  fireSearchLinkTracking,
  fireSelectAutocompleteTracking,
  fireTemporaryKeydownTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
import { ApiMetrics } from 'client/utils/metrics-hoc';
/* Hooks */
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
import { useGlobalSearchResults } from 'site-modules/shared/hooks/global-search/use-global-search-results';
/* Constants */
import {
  AUTOSIZED_AREA_LENGTH,
  CLEAR_INPUT,
  CREATIVE_ID,
  INPUT_DELAY,
  INPUT_MIN_HEIGHT,
  LISTBOX_ID,
  LOOKING_FOR_PLACEHOLDER,
  OPEN_DELAY,
  PRIMING_BOX_COPY,
} from 'site-modules/shared/constants/global-search/global-search';
import { KEY_CODES } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-autocomplete';
import { TrackingConstant } from 'client/tracking/constant';
/* Components */
import { Collapse } from 'site-modules/shared/components/collapse/collapse';
import { RestoreFocus } from 'site-modules/shared/components/restore-focus/restore-focus';
import { AutosizedArea } from 'site-modules/shared/components/inventory/autosized-area/autosized-area';
import { GlobalSearchDrawer } from 'site-modules/shared/components/inventory/global-search/global-search-drawer/global-search-drawer';
import { GlobalSearchDropdown } from 'site-modules/shared/components/inventory/global-search/global-search-dropdown/global-search-dropdown';

import './global-search.scss';

export function GlobalSearch({
  isMobile,
  searchId,
  query,
  apiMetrics,
  onSearchSubmit,
  onSearchChange,
  withLlmSearchDisabled,
  isLoading,
  isError,
  loadingComponent,
  controlled,
  isOpen: isOpenProp,
  onChangeOpenState,
  creativeId,
  keepUserSearchOnDrawerClose,
  keepClearButtonPermanently,
  trapFocus,
  inputClassname,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [animating, setAnimating] = useState(false);
  const trimmedQuery = query?.trim();
  const [isSelectOptionMessageShown, setIsSelectOptionMessageShown] = useState(false);
  const debouncedSetIsOpen = useDebounce(setIsOpen, OPEN_DELAY);
  const { results: fastMatcherData, isLoading: isFastMatcherLoading } = useGlobalSearchResults(trimmedQuery, {
    apiMetrics,
    delay: INPUT_DELAY,
    isOpen,
  });

  const handleInputChange = useCallback(
    async value => {
      debouncedSetIsOpen(true);
      setAnimating(true);
      setIsSelectOptionMessageShown(false);
      await onSearchChange(value);
    },
    [debouncedSetIsOpen, onSearchChange]
  );

  const handleSearchSubmit = useCallback(
    async event => {
      event.preventDefault();
      fireSearchLinkTracking({
        input: trimmedQuery,
        fastMatcherData,
        value: `search ${trimmedQuery}`,
        selectionType: 'search',
        creativeId,
      });
      setIsSelectOptionMessageShown(false);

      await onSearchSubmit(trimmedQuery);
    },
    [trimmedQuery, fastMatcherData, creativeId, onSearchSubmit]
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
    setAnimating(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    setIsSelectOptionMessageShown(false);
    fireAbandonTracking({ input: trimmedQuery, fastMatcherData, creativeId });
  }, [trimmedQuery, fastMatcherData, creativeId]);

  const handleEscKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_CODES.escape) {
        handleDialogClose();
      }
    },
    [handleDialogClose]
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault();
        setIsSelectOptionMessageShown(true);
      }

      fireTemporaryKeydownTracking({ event, creativeId });
    },
    [creativeId]
  );

  const onQueryCancel = useCallback(async () => {
    setIsSelectOptionMessageShown(false);
    await onSearchChange('');
  }, [onSearchChange]);

  const handleQueryCancel = useCallback(async () => {
    await onQueryCancel();
    setIsOpen(true);
    fireResetTracking({
      input: trimmedQuery,
      fastMatcherData,
      creativeId,
      ...(keepClearButtonPermanently ? { searchId } : {}),
    });
  }, [onQueryCancel, trimmedQuery, fastMatcherData, creativeId, keepClearButtonPermanently, searchId]);

  const handleOnDrawerClose = useCallback(async () => {
    handleDialogClose();
    if (!keepUserSearchOnDrawerClose) {
      await onQueryCancel();
    }
  }, [handleDialogClose, keepUserSearchOnDrawerClose, onQueryCancel]);

  useEffect(() => {
    if (isOpen) {
      fireSelectAutocompleteTracking({
        eventType: TrackingConstant.EVENT_TYPE_ACTION_START,
        value: LOOKING_FOR_PLACEHOLDER,
        creativeId,
      });
      fireSelectAutocompleteTracking({ eventType: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS, creativeId });
    }
    onChangeOpenState(isOpen);
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (controlled) {
      setIsOpen(isOpenProp);
    }
    // eslint-disable-next-line
  }, [controlled, isOpenProp]);

  const formContainer = (
    <div
      className={classnames('global-search-form-container pos-a', {
        'w-100 mobile': isMobile,
        'is-open': isOpen,
        animating,
      })}
    >
      <div className="bg-white global-search-form-wrapper">
        <Form
          id="global-search-form"
          name="global-search-form"
          noValidate
          className="global-search-form pos-r"
          onSubmit={handleSearchSubmit}
          onKeyDown={handleEscKeyDown}
          data-tracking-parent={creativeId}
        >
          <div className="pos-r">
            <div className="search-icon pos-a d-flex justify-content-center align-items-center h-100">
              <img src={getStaticImageUrl('/icons/magic-search.svg')} alt="" decoding="async" loading="lazy" />
            </div>
            <AutosizedArea
              className={classnames(
                'global-search-input size-16',
                { 'hide-caret': isMobile && isOpen },
                inputClassname
              )}
              onChange={handleInputChange}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              hintText={LOOKING_FOR_PLACEHOLDER}
              inputValue={query}
              inputMinHeight={INPUT_MIN_HEIGHT}
              maxLength={AUTOSIZED_AREA_LENGTH}
              autosizeOnFocus={!isOpen}
              disableOutline
              role="combobox"
              aria-expanded={isOpen}
              aria-haspopup="dialog"
              aria-controls={LISTBOX_ID}
              aria-label="Search:"
            />
          </div>
          {!!query && (isOpen || keepClearButtonPermanently) && (
            <Button
              onClick={handleQueryCancel}
              className="query-cancel-button pos-a p-0 border-0 background-none d-flex justify-content-center align-items-center"
            >
              <i className="text-cool-gray-50 icon-cross2 small" role="img" aria-label={CLEAR_INPUT} />
            </Button>
          )}
          {!isMobile && (
            <Collapse
              isOpen={isOpen}
              transition="height 300ms linear"
              id={LISTBOX_ID}
              role="dialog"
              aria-label={PRIMING_BOX_COPY}
            >
              <GlobalSearchDropdown
                isOpen={isOpen}
                searchQuery={query}
                onCloseButtonClick={handleDialogClose}
                isError={isError}
                fastMatcherData={fastMatcherData}
                isFastMatcherLoading={isFastMatcherLoading}
                searchId={searchId}
                isSelectOptionMessageShown={isSelectOptionMessageShown}
                withLlmSearchDisabled={withLlmSearchDisabled}
                creativeId={creativeId}
              />
            </Collapse>
          )}
        </Form>
      </div>
    </div>
  );

  return (
    <div className="global-search pos-r w-100 d-flex mb-1_5 justify-content-center px-1">
      {!isMobile && isOpen && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={handleDialogClose}
          className={classnames('page-overlay top-0 right-0 bottom-0 left-0', {
            show: isOpen,
          })}
          data-testid="page-overlay"
        />
      )}
      {!isMobile && (
        <RestoreFocus isInnerScopeActive={isOpen}>
          <FocusScope contain={isOpen}>
            <FocusRing within focusRingClass="focus-visible">
              {formContainer}
            </FocusRing>
            {isLoading && loadingComponent}
          </FocusScope>
        </RestoreFocus>
      )}
      {isMobile && (
        <>
          {formContainer}
          <GlobalSearchDrawer
            id={LISTBOX_ID}
            ariaLabel={PRIMING_BOX_COPY}
            searchId={searchId}
            query={query}
            creativeId={creativeId}
            fastMatcherData={fastMatcherData}
            onInputChange={handleInputChange}
            onQueryCancel={handleQueryCancel}
            onSearchSubmit={handleSearchSubmit}
            onDrawerClose={handleOnDrawerClose}
            loadingComponent={loadingComponent}
            isFastMatcherLoading={isFastMatcherLoading}
            isOpen={isOpen}
            isLoading={isLoading}
            isError={isError}
            withLlmSearchDisabled={withLlmSearchDisabled}
            isMobile
            trapFocus={trapFocus}
          />
        </>
      )}
    </div>
  );
}

GlobalSearch.propTypes = {
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  query: PropTypes.string,
  loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  searchId: PropTypes.string,
  apiMetrics: ApiMetrics,
  onSearchSubmit: PropTypes.func,
  onSearchChange: PropTypes.func,
  withLlmSearchDisabled: PropTypes.bool,
  controlled: PropTypes.bool,
  isOpen: PropTypes.bool,
  keepClearButtonPermanently: PropTypes.bool,
  onChangeOpenState: PropTypes.func,
  creativeId: PropTypes.string,
  keepUserSearchOnDrawerClose: PropTypes.bool,
  trapFocus: PropTypes.bool,
  inputClassname: PropTypes.string,
};

GlobalSearch.defaultProps = {
  isMobile: false,
  isLoading: false,
  isError: false,
  loadingComponent: undefined,
  query: '',
  searchId: '',
  apiMetrics: {},
  onSearchSubmit: noop,
  onSearchChange: noop,
  withLlmSearchDisabled: false,
  controlled: false,
  isOpen: false,
  keepClearButtonPermanently: false,
  onChangeOpenState: noop,
  creativeId: CREATIVE_ID,
  keepUserSearchOnDrawerClose: false,
  trapFocus: undefined,
  inputClassname: '',
};
