import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';

import './disclaimer.scss';

const Styles = {
  button: {
    fontWeight: 'normal',
    fontSize: '0.625rem',
    lineHeight: '1.5rem',
    letterSpacing: 0,
    textAlign: 'right',
    padding: '0 4px',
    textTransform: 'capitalize',
  },
};

export function Disclaimer({
  linkDisclaimer,
  disclaimerText,
  contentClasses,
  openTrackingId,
  closeTrackingId,
  translateY,
  absoluteBottomValue,
  buttonStyle,
  buttonClasses,
}) {
  const [isHidden, toggleHidden] = useToggle(true);

  return (
    <Fragment>
      <Button
        className={classnames('disclaimer-button disclaimer', buttonClasses)}
        style={{ ...Styles.button, ...buttonStyle }}
        color="link"
        onClick={toggleHidden}
        data-tracking-id={openTrackingId}
        aria-label={`Advertising ${linkDisclaimer}`}
      >
        {linkDisclaimer}
      </Button>
      <div
        className={classnames(`disclaimer-content text-start px-1 py-1`, contentClasses, {
          show: !isHidden,
        })}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: absoluteBottomValue,
          transform: `translateY(${translateY}%)`,
        }}
      >
        <button
          className="disclaimer-close border-0 p-0_25 m-0_5 icon-cancel-circle2 text-gray pos-a top-0 right-0"
          onClick={toggleHidden}
          data-tracking-id={closeTrackingId}
          disabled={isHidden}
          aria-label={`Close ${linkDisclaimer}`}
        />
        <ContentFragment classes="text-gray-darker size-12 pr-0_75">{disclaimerText}</ContentFragment>
      </div>
    </Fragment>
  );
}

Disclaimer.propTypes = {
  linkDisclaimer: PropTypes.string,
  disclaimerText: PropTypes.string,
  contentClasses: PropTypes.string,
  openTrackingId: PropTypes.string,
  closeTrackingId: PropTypes.string,
  translateY: PropTypes.number,
  absoluteBottomValue: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  buttonClasses: PropTypes.string,
};

Disclaimer.defaultProps = {
  linkDisclaimer: 'Disclaimer',
  disclaimerText: '',
  contentClasses: '',
  openTrackingId: '',
  closeTrackingId: '',
  translateY: 101,
  absoluteBottomValue: '0',
  buttonStyle: {},
  buttonClasses: 'text-gray-darker',
};
