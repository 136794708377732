import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import {
  BUTTON_DATA,
  CLICK_OR_TAP_ACTION,
  CREATIVE_ID,
  CREATIVE_ID_SEMANTIC,
  KEYBOARD_ACTION,
} from 'site-modules/shared/constants/global-search/global-search';
import { compact, flatten } from 'lodash';
import {
  getCompareLink,
  getPricingReviewsLinks,
  getResearchLinks,
  getResearchTypeLinks,
  getSrpLink,
} from 'site-modules/shared/utils/inventory/global-search-links';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import { ENTER_KEY_VALUE } from 'site-modules/shared/constants/key-values';

export const fireSelectAutocompleteTracking = ({ eventType, value, creativeId = CREATIVE_ID }) => {
  EventToolbox.fireTrackAction({
    event_type: eventType,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.SELECT_AUTOCOMPLETE,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
      ...(value ? { value } : {}),
    },
  });
};

export const fireWidgetViewTracking = ({ value, creativeId = CREATIVE_ID }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      total_page_height: Math.max(document.body.scrollHeight, document.body.offsetHeight),
      creative_id: creativeId,
      value,
    },
  });
};

function getAutocompleteResults({ input, fastMatcherData }) {
  return input
    ? [
        { title: `search ${input}` },
        ...(fastMatcherData
          ? flatten(
              compact([
                compact([
                  getSrpLink({
                    fastMatcherData,
                    types: [INVENTORY_TYPES_LOWERCASE.USED, INVENTORY_TYPES_LOWERCASE.CPO],
                  }),
                ]),
                compact([getSrpLink({ fastMatcherData, types: [INVENTORY_TYPES_LOWERCASE.NEW] })]),
                compact([getCompareLink({ fastMatcherData })]),
                getPricingReviewsLinks({ fastMatcherData })?.map(({ title }) => ({ title: `${title} new review` })),
                getResearchLinks({ fastMatcherData }),
                getResearchTypeLinks({ fastMatcherData }),
              ])
            )
          : []),
      ]
        .map(({ title }) => title)
        .join(', ')
    : BUTTON_DATA.map(({ label }) => label).join(', ');
}

export const fireAbandonTracking = ({ input, fastMatcherData, creativeId = CREATIVE_ID }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.ABANDON_AUTO_COMPLETE_RESULT,
      creative_id: creativeId,
      input: input || 'null',
      ...(input ? { value: `mmt_search_id=${fastMatcherData?.trace?.uuid}` } : {}),
      autocomplete_results: getAutocompleteResults({ input, fastMatcherData }),
    },
  });
};

export const fireSearchLinkTracking = ({
  input,
  fastMatcherData,
  value,
  selectionType,
  mmtSearchId,
  creativeId = CREATIVE_ID,
}) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.VIEW_AUTO_COMPLETE_RESULT,
      input,
      autocomplete_results: getAutocompleteResults({ input, fastMatcherData }),
      value: mmtSearchId ? `mmt_search_id=${mmtSearchId}&value=${value}` : value,
      selection_type: selectionType,
      creative_id: creativeId,
    },
  });
};

export const fireSubmitTracking = ({ input, searchId, creativeId = CREATIVE_ID_SEMANTIC }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.VIEW_AUTO_COMPLETE_RESULT,
      value: [`srp_search_id=${searchId}`, `search=${input}`].join('&'),
      creative_id: creativeId,
    },
  });
};

export const fireResetTracking = ({ input, fastMatcherData, creativeId = CREATIVE_ID, searchId }) => {
  const value = fastMatcherData?.trace?.uuid
    ? `mmt_search_id=${fastMatcherData.trace.uuid}`
    : `srp_search_id=${searchId}`;

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_RESET_NAVIGATION,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.CLOSE_DRAWER,
      input,
      value,
      autocomplete_results: getAutocompleteResults({ input, fastMatcherData }),
      creative_id: creativeId,
    },
  });
};

export const fireTemporaryClickTracking = ({ creativeId = CREATIVE_ID_SEMANTIC }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      creative_id: creativeId,
      value: CLICK_OR_TAP_ACTION,
    },
  });
};

export const fireTemporaryKeydownTracking = ({ event, creativeId = CREATIVE_ID_SEMANTIC }) => {
  if (event.key === ENTER_KEY_VALUE) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_CONTENT,
        subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: creativeId,
        value: KEYBOARD_ACTION,
      },
    });
  }
};
